import apiClient from "../../axios/axios";

export async function update_current_stage(data) {
  try {
    const endpoint = `/update_current_stage`;
    const res = await apiClient.post(endpoint, data);
    console.log("RES STATUS: ", res);
    return { status: res.status };
  } catch (err) {
    console.log("Err on lifecycle store setter");
  }
}
